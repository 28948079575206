/* General Styles */
.App {
  text-align: center;
}

body {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
}

.element {
  width: 100%;
  height: 100%;
  background: 
    radial-gradient(37.62% 18.31% at 3.41% 0.79%, rgba(246, 0, 255, 0.2) 8.85%, rgba(155, 137, 242, 0) 100%), 
    radial-gradient(61.41% 11.63% at 97.28% 16.55%, rgba(38, 19, 255, 0.6) 0%, rgba(63, 17, 175, 0.18) 50.52%, rgba(42, 10, 122, 0) 100%), 
    radial-gradient(150.73% 30.43% at 77.35% -1.59%, #000000 20.31%, rgba(111, 115, 152, 0) 100%), 
    radial-gradient(86.21% 40.9% at 21.6% 0%, #E04CBB 0%, rgba(136, 122, 244, 0.54) 71.35%, rgba(160, 136, 255, 0) 100%), 
    radial-gradient(94% 66.9% at 0% -4.81%, #EBBCFE 6.77%, #BABFF7 39.06%, #BADAFE 55.21%, #BBDDFF 71.88%, rgba(252, 238, 250, 0) 100%),
    radial-gradient(147.64% 47.68% at 115.2% 72.76%, #FE2819 8.85%, rgba(255, 202, 99, 0.8) 58.33%, rgba(223, 223, 223, 0) 100%),
    radial-gradient(84.4% 21.83% at 4.93% 37.79%, #FE5D50 0%, rgba(254, 172, 113, 0.65) 33.33%, rgba(255, 255, 255, 0) 100%), 
    radial-gradient(112.29% 50.65% at 0% 100%, #3B2DDE 10.94%, rgba(235, 196, 253, 0.8) 77.6%, rgba(223, 223, 223, 0) 100%), 
    #FCEEFA;
  position: absolute;
  z-index: -100;

}

.video::-webkit-media-controls {
  display: none !important;
}

/* Header Styles */
.title-bar {
  background-color: rgba(0,0,0,0.81);
  color: #eb721b;
  font-size: 3rem;
  font-weight: bold;
  letter-spacing: 3px;
  text-align: center;
  margin: 0 auto 8px;
  padding: 12px 24px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  height: 88px;
  opacity: 100%;
  text-align: center;
}

.title-bar-text {
  color: #eb721b;
  font-size: 3rem;
  font-weight: bold;
  letter-spacing: 3px;
  text-align: center;
  transition: transform 0.3s ease;
}

.title-bar-text:hover {
  transform: scale(1.05);
}

/* App Content Styles */
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.content {
  position: relative;
  z-index: 1;
  text-align: center;
  color: white;
  height: calc(99vh - 60px);
  top: 92px;
  display: flex;
  flex-direction: column;
}

.default-view {
  overflow-y: auto;
  padding-bottom: 24px;
}

.detail-view {
  align-items: center;
  align-self: center;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  padding-bottom: 40px!important;
  height: calc(94vh - 60px);
}

/* Header Navigation Styles */
.fixed-header {
  position: sticky;
  top: 0;
  background-color: transparent;
  display: flex;
  margin-top: 16px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px 0 10px;
  margin-bottom: 0px!important;
  z-index: 2;
}

.search-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 66vw;
  padding: 8px 20px 8px 16px;
  border: 2.5px solid #ddd;
  border-radius: 12px;
  background-color: rgba(252, 252, 252, 0.6);
  transition: transform 0.3s ease, border 0.3s ease;
}

.search-container:focus {
  transform: scale(1);
}

.search-container:hover {
  /* transform: scale(1.05); */
  border: 2.5px solid #eb721b!important;
}

.reserveButton {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 3px solid #eb721b;
  width: 30vw;
  height: 40px;
  background-color: rgba(12, 12, 12, 0.8);
  color: white;
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
  padding: 2px 8px;
  margin: 32px 0 8px;
}

.reserve-btn {
  transition: transform 0.3s ease!important;
}

.reserve-btn:hover {
  transform: scale(1.05);
}

.clear-button {
  cursor: pointer;
  display: none;
  padding-left: 20px;
  color: black;
}

.search-field {
  border: 0 none transparent;
  background-color: transparent;
  font-size: 18px;
  font-weight: 700;
  width: 90vw;
  overflow: hidden;
  color: black!important;
  caret-color: #eb721b;
}

.search-field:focus {
  outline: none;
}

.search-field::placeholder {
  color: white;
}

.back-button {
  padding: 12px;
  border: 2.5px solid #888;
  border-radius: 12px;
  font-size: 21px;
  font-weight: 800;
  letter-spacing: 1px;
  width: 30vw;
  background-color: rgba(252, 252, 252, 0.8);
  color: black;
  text-decoration: none;
}

/* Event List Styles */
.events-header {
  text-align: left;
  width: 86vw;
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.event-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
}

.event-cell {
  display: flex;
  align-items: center;
  padding: 12px 10px 0 10px;
  margin: 4px 0 20px 0;
  background-color: transparent;
  border-radius: 8px;
  color: black;
  font-weight: 500;
  overflow-x: hidden;
  opacity: 100%;
  background: linear-gradient(to bottom, rgba(252, 252, 252, 1.0), rgba(252, 252, 252, 0));
  transition: transform 0.3s ease, border 0.3s ease;
}

.event-cell:hover {
  /* padding: 20px 20px 0 20px; */
  transform: scale(1.02);
}

.payment-form {
  position: absolute;
  top: 20vh;
  bottom: 20vh;
  left: 20vw;
  right: 20vw;
  z-index: 999;
  font-weight: 700;
}

.profile-btn {
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.profile-btn:hover {
  background-color: #d66819!important;
}

.profile-btn:focus {
  transform: scale(1);
}

/* ProfileView Styles */
.profile-view {
  display: none;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.81);
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.profile-modal {
  background-color: white;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0px 0px 10px rgba(235, 114, 27, 0.2);
  height: 30vh;
  width: 36vw;
  margin-top: -15vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Add styles for the close button (X) */
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  color: black;
  cursor: pointer;
}

.profile-header h2 {
  font-size: 24px;
  margin: 0;
}

.profile-content {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.profile-content input {
  width: 80%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: center;
}

.profile-content button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 700;
  font-size: 15px;
  letter-spacing: 0.5px;
}

.profile-footer {
  text-align: right;
  margin-top: 10px;
}